import React from "react";
import cvData from "../data/cvData.json";

const SoftSkills = () => {
  const { softSkills } = cvData;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4">
      <h2 className="text-3xl font-bold mb-6 text-accent">SoftSkills</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {softSkills.map((softSkill, index) => (
          <div
            key={index}
            className="p-4 bg-background-dark-accent rounded-md text-secondary-color font-medium break-words"
          >
            {softSkill}
          </div>
        ))}
      </div>
    </section>
  );
};

export default SoftSkills;
