import React from 'react';
import cvData from '../data/cvData.json';

const Education = () => {
  const { education } = cvData;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4 lg:mx-52">
      <h2 className="text-3xl font-bold mb-6  text-accent">Education</h2>
      {education.map((edu, index) => (
        <div
          key={index}
          className="mt-6 p-4 bg-background-dark-accent rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
        >
          <h3 className="text-2xl font-semibold text-accent">{edu.institution}</h3>
          <p className="font-medium mt-2 text-text-color">{edu.degree}</p>
          <p className="text-gray-400 italic mt-2">{edu.year}</p>
        </div>
      ))}
    </section>
  );
};

export default Education;