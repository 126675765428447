import React from "react";
import cvData from "../data/cvData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="bg-background-dark text-text-color p-4 mt-12">
      <div className="flex justify-center space-x-6">
        {cvData.socialMedia.map((social, index) => (
          <a
            key={index}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl hover:text-main-color"
          >
            <FontAwesomeIcon icon={`fa-brands fa-${social.icon}`} />
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
