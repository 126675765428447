export const convertTextToLinks = (text) => {
    const urlPattern = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;
  
    while ((match = urlPattern.exec(text)) !== null) {
      parts.push(text.substring(lastIndex, match.index));
      parts.push(
        <a key={match[2]} href={match[2]} target="_blank" rel="noopener noreferrer" className="text-accent underline">
          {match[1]}
        </a>
      );
      lastIndex = urlPattern.lastIndex;
    }
  
    parts.push(text.substring(lastIndex));
    return parts;
  };

  export const calculateDuration = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = endDateStr.toLowerCase() === "present" ? new Date() : new Date(endDateStr);
  
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const months = Math.floor(diffDays / 30);
    const years = Math.floor(months / 12);
  
    return { years, months: months % 12 };
  };
  
  export const calculateTotalExperience = (experience) => {
    let totalYears = 0;
    let totalMonths = 0;
  
    experience.forEach((job) => {
      const duration = calculateDuration(job.startDate, job.endDate);
      totalYears += duration.years;
      totalMonths += duration.months;
    });
  
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
  
    return { totalYears, totalMonths };
  };
  