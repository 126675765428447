import React from "react";
import cvData from "../data/cvData.json";

const AboutMe = () => {
  const { aboutMe } = cvData;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4 lg:mx-52">
      <h2 className="text-3xl font-bold mb-6 text-accent">About Me</h2>
      <div className="mt-6 p-4 bg-background-dark-accent rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
        {aboutMe.map((paragraph, index) => (
          <p key={index} className="mt-2 mb-2 text-gray-300 md:mx-20">
            {paragraph}
          </p>
        ))}
      </div>
    </section>
  );
};

export default AboutMe;
