import React from "react";
import cvData from "../data/cvData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  const { phone, address, email } = cvData.personalInfo;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4 lg:mx-52">
      <h2 className="text-3xl font-bold mb-6">Contact</h2>
      <div className="space-y-4 text-center">
        <div className="space-y-2 space-x-2">
          <FontAwesomeIcon icon={`fa-brands fa-whatsapp`} />
          <a
            href={`https://wa.me/${phone}`}
            className="text-main-color underline"
          >
            {phone}
          </a>
        </div>
        <div className="space-y-2 space-x-2">
          <FontAwesomeIcon icon={`fa-solid fa-location-dot`} />
          <a
            href={`https://www.google.com/maps/place/Cape+Town/@-33.9145291,18.3264266,10z/data=!3m1!4b1!4m6!3m5!1s0x1dcc500f8826eed7:0x687fe1fc2828aa87!8m2!3d-33.9248685!4d18.4240553!16zL20vMDF5ajI?entry=ttu`}
            className="text-main-color underline"
          >
            {address}
          </a>
        </div>
        <div className="space-y-2 space-x-2">
          <FontAwesomeIcon icon={`fa-solid fa-envelope`} />
          <a href={`mailto:${email}`} className="text-main-color underline">
            {email}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
