import React, { useEffect, useState } from "react";
import cvData from "../data/cvData.json";

const Header = () => {
  const { name, title } = cvData.personalInfo;
  const [isHeaderShrunk, setIsHeaderShrunk] = useState(false);

  function throttle(func, delay) {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= delay) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, delay - (Date.now() - lastRan));
      }
    };
  }

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 50) {
        setIsHeaderShrunk(true);
      } else {
        setIsHeaderShrunk(false);
      }
    }, 10);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="h-52 sticky top-0 z-50">
      <header
        className={`bg-background-dark text-text-color p-4 flex items-center justify-center space-x-4 transition-all ease-in-out duration-300 transform backface-visibility-hidden shadow-md ${
          isHeaderShrunk ? "h-20 shadow-lg" : "h-52"
        }`}
      >
        <div
          className={`transition-all ease-in-out duration-300 ${
            isHeaderShrunk ? "" : "py-4"
          }`}
        >
          <img
            src={"/assets/images/profile.jpg"}
            alt={name}
            className={`rounded-full transition-all ease-in-out duration-300 ${
              isHeaderShrunk ? "h-12 w-12" : "h-36 w-36"
            }`}
          />
        </div>

        <div
          className={`flex flex-col transition-opacity ease-in-out duration-300`}
        >
          <h1 className="text-4xl font-bold text-accent">{name}</h1>
          <p
            className={`text-lg ${
              isHeaderShrunk ? "text-base" : "text-lg"
            } text-accent`}
          >
            {title}
          </p>
          {!isHeaderShrunk && (
            <>
              <a
                href={"/assets/cv/CV.pdf"}
                download="Deklerk_Basson_CV"
                target="_blank"
                rel="noreferrer"
                className="bg-background-dark-secondary text-white px-4 py-2 rounded-lg hover:bg-accent transition-colors duration-300"
              >
                <button>Download my CV</button>
              </a>
            </>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
