import React from "react";
import cvData from "../data/cvData.json";

const Projects = () => {
  const { projects } = cvData;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4 lg:mx-52">
      <h2 className="text-3xl font-bold mb-6 text-accent">Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {projects.map((project, index) => (
          <div
            key={index}
            className="p-4 bg-background-dark-accent rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
          >
            <h3 className="text-2xl font-semibold text-accent mb-2">
              {project.name}
            </h3>
            <p className="text-sm text-text-color mb-4">{project.description}</p>
            <div className="grid grid-cols-2 gap-2 mb-4">
              {project.technologies.map((tech, techIndex) => (
                <div
                  key={techIndex}
                  className="p-1 bg-background-dark-secondary rounded-md text-sm text-text-color font-medium"
                >
                  {tech}
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 gap-2 mb-4">
              <a
                href={project.link}
                className="px-4 py-2 bg-main-color text-white rounded-md text-sm font-semibold hover:bg-background-dark-secondary transition-colors duration-200 shadow-md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Demo
              </a>
              <a
                href={project.repo}
                className="px-4 py-2 bg-main-color text-white rounded-md text-sm font-semibold hover:bg-background-dark-secondary transition-colors duration-200 shadow-md"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub Repo
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
