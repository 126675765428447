import React from "react";
import "./App.css";
import Header from "./components/Header";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Projects from "./components/Projects";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import AboutMe from "./components/AboutMe";
import IdealNextJob from "./components/IdealNextJob";
import SoftSkills from "./components/SoftSkills";
import Hobbies from "./components/Hobbies";
library.add(fab, fas);

function App() {
  return (
    <div className="App bg-background-dark-secondary">
      <Header />
      <AboutMe />
      <Experience />
      <Skills />
      <IdealNextJob />
      <Education />
      <Projects />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8 lg:mx-52">
        <Hobbies />
        <SoftSkills />
      </div>

      <Contact />
      <Footer />
    </div>
  );
}

export default App;
