import React from "react";
import cvData from "../data/cvData.json";
import moment from "moment";
import { convertTextToLinks } from "../helpers/helper";

const calculateDuration = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate =
    endDateStr.toLowerCase() === "present" ? new Date() : new Date(endDateStr);

  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const months = Math.floor(diffDays / 30);
  const years = Math.floor(months / 12);

  return years
    ? `${years} ${years === 1 ? "year" : "years"} ${months % 12} ${
        months % 12 === 1 ? "month" : "months"
      }`
    : `${months} ${months === 1 ? "month" : "months"}`;
};

const Experience = () => {
  const { experience } = cvData;

  return (
    <section className="bg-background-dark-secondary text-text-color p-4 lg:mx-52">
      <h2 className="text-3xl font-bold mb-6 text-accent">Experience</h2>
      {experience.map((exp, index) => (
        <div
          key={index}
          className="mt-6 p-4 bg-background-dark-accent rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
        >
          <h3 className="text-2xl font-semibold text-accent">{exp.title}</h3>
          <p className="text-xl font-medium text-gray-200 mt-2">{exp.company}</p>
          <p className="text-gray-400 italic mt-2">{`${moment(
            exp.startDate
          ).format("Do of MMMM, YYYY")} - ${
            exp.endDate !== "present"
              ? moment(exp.endDate).format("Do of MMMM, YYYY")
              : "present"
          }`}</p>
          <p className="font-bold">
            ({calculateDuration(exp.startDate, exp.endDate)})
          </p>
          <div className="ml-4 mt-4">
            {exp.responsibilities.map((res, i) => (
              <p key={i} className="mb-2 text-gray-300">
                - {convertTextToLinks(res)}
              </p>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Experience;
